
@media screen and (min-width:1921px){


}
@media screen and (min-width:2500px){

}
@media screen and (min-width:2500px){

}
@media screen and (max-width:1200px){
   
}





@media screen and (min-width: 1100px) and (max-width: 1350px){
} 

@media screen and (min-width: 992px) and (max-width: 1099px){
} 

      

@media (max-width:992px){
  
}

@media (max-width:991px){
    .dark-bg-black {
        background-size: 50%;
    }
    .header-menu {
        padding-bottom: 20px;
    }
    .header-menu a {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .mobile-green-btn {
        height: 45px;
    }
    .mr-c-50{
        margin-right: 0px;
    }
    .mr-c-30{
        margin-right: 0px;
    }
    .navbar-collapse-mobile {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        position: absolute;
        top: 90px;
        background-color: #000;
        width: 100%;
        left: 0;
        z-index: 1;
    }
    .bg-cover-img {
        background-size: cover;
        background-position: bottom;

    }
    .green-btn-square-form {
        width: 100%;
    }
}
@media (max-width:767px){
    .coming-soon-img {
        width: 100%;
    }
    .box {
        width: 50px;
        font-size: 13px;
    }
    .gry-list-points {
        padding: 0;
    }
    .green-bold-text {
        font-size: 55px;
    }
    .white-bold-text {
        font-size: 55px;
    }
    .green-btn-square-form {
        width: 100%;
    }
   
 }
@media (max-width: 700px){ 
}
@media (min-width:1600px){
   
}

@media (min-width:1400px){  
}

@media (max-width:1160px){
}
@media (max-width:1100px) and (min-width:992px){

}

@media (max-width:600px) {
    .bg-cover-img-mobile{
        background-image: url(../Image/bg-cover-mobile.jpeg);
        text-align: center;
        height: 520px;
        background-repeat: no-repeat;
        padding-top: 130px;
        background-size: cover;
        display: block !important;
    }
    .bg-cover-img{
        display: none;
    }
}
@media (max-width: 576px){ 
    .coming-soon h1 {
        font-size: 38px;
    }
   
    .black-outline-btn-square-form {
        width: 100%;
    }
    .login-form  .modal-content {
        padding: 0px;
    }
    .check-input {
        width: 50px;
    }
    .padding-mob10{
        padding: 10px !important;
    }
    .number-show {
        width: 50px;
        height: 50px;
    }
    .step-title {
        font-size: 14px;
    }
}
@media (max-width: 480px){
    .gry-text-italic {
        margin-top: 30px;
    }
    .group-input-signup .input-group-text {
        padding: 5px;
    }
    .group-input-signup input {
        width: 85% !important;
    }
    .group-input-signup .input-group-prepend {
        width: 15%;
    }
    .white-card {
        padding: 30px;
    }
    .green-btn-square-form {
        width: 100%;
    }
}
@media (max-width: 374px){
    .logo-header img {
        width: 210px;
    }
    .mobile-padding{
        padding: 60px 30px !important;
    }
   
}
