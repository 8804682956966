/* Font family */

/* Helvetica Bold  */
@font-face {
    font-family: 'helvetica_neue_lt_pro75_bold';
    src: url('../Font/helveticaneueltpro-bd-webfont.woff2') format('woff2'),
         url('../Font/helveticaneueltpro-bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* Helvetica Regular  */
@font-face {
    font-family: 'helvetica_neue55_roman';
    src: url('../Font/helveticaneue-roman-webfont.woff2') format('woff2'),
         url('../Font/helveticaneue-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Helvetica Medium  */
@font-face {
    font-family: 'helvetica_neue65_medium';
    src: url('../Font/helvetica-neue-65-medium-webfont.woff2') format('woff2'),
         url('../Font/helvetica-neue-65-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* Helvetica Thin Light  */
@font-face {
    font-family: 'helvetica_neue_lt_proregular';
    src: url('../Font/helvetica_neue_lt_pro_35_thin-webfont.woff2') format('woff2'),
         url('../Font/helvetica_neue_lt_pro_35_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* Helvetica Light  */
@font-face {
    font-family: 'helveticaneue_lt_45_lightRg';
    src: url('../Font/helvetica_lt_45_light-webfont.woff2') format('woff2'),
         url('../Font/helvetica_lt_45_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_probold';
    src: url('../Font/helvetica_neue_lt_pro_85_heavy-webfont.woff2') format('woff2'),
         url('../Font/helvetica_neue_lt_pro_85_heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'made_outer_sansblack';
    src: url('../Font/made_outer_sans_black-webfont.woff2') format('woff2'),
         url('../Font/made_outer_sans_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
font-family: 'made_outer_sansbold';
src: url('../Font/made_outer_sans_bold-webfont.woff2') format('woff2'),
     url('../Font/made_outer_sans_bold-webfont.woff') format('woff');
font-weight: normal;
font-style: normal;

}




@font-face {
font-family: 'made_outer_sanslight';
src: url('../Font/made_outer_sans_light-webfont.woff2') format('woff2'),
     url('../Font/made_outer_sans_light-webfont.woff') format('woff');
font-weight: normal;
font-style: normal;

}




@font-face {
font-family: 'made_outer_sansmedium';
src: url('../Font/made_outer_sans_medium-webfont.woff2') format('woff2'),
     url('../Font/made_outer_sans_medium-webfont.woff') format('woff');
font-weight: normal;
font-style: normal;

}




@font-face {
font-family: 'made_outer_sansregular';
src: url('../Font/made_outer_sans_regular-webfont.woff2') format('woff2'),
     url('../Font/made_outer_sans_regular-webfont.woff') format('woff');
font-weight: normal;
font-style: normal;

}




@font-face {
font-family: 'made_outer_sansthin';
src: url('../Font/made_outer_sans_thin-webfont.woff2') format('woff2'),
     url('../Font/made_outer_sans_thin-webfont.woff') format('woff');
font-weight: normal;
font-style: normal;

}

/*helvetica font*/
@font-face {
    font-family: 'helveticaregular';
    src: url('../Font/helvetica font/helvetica_regular-webfont.woff2') format('woff2'),
         url('../Font/helvetica font/helvetica_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'helveticabold';
    src: url('../Font/helvetica font/helvetica-bold-font-webfont.woff2') format('woff2'),
         url('../Font/helvetica font/helvetica-bold-font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;    
}
@font-face {
    font-family: 'helvetica_mediumregular';
    src: url('../Font/helvetica font/helvmn-webfont.woff2') format('woff2'),
         url('../Font/helvetica font/helvmn-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: #00ff37 !important;
    border-color: #00ff37 !important;
} */
body{
    font-family: 'helveticaregular';
    background-color: #F8F9FB;
}
/*header*/
.dark-black-bg {
    background-color: #000;
    height: 96px;
}
.header-menu {
    display: flex;
    align-items: center;
}
.white-link {
    color: #fff !important;
    font-family: 'helvetica_neue65_medium';
    text-decoration: none;

}
.white-link:hover {
    color: #fff;
}
.heading-height {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.green-btn {
    background-color: #00FF37;
    color: #000 !important;
    border-radius: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 30px !important;
    font-family: 'helvetica_neue65_medium';
    line-height: 0;
    text-decoration: none;
}
.green-btn:focus {
    color: #000 !important;
}
.green-btn:active {
    color: #000 !important;
}

.purple-btn {
    background-color: #7472F6;
    color: #fff !important;
    border-radius: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 30px !important;
    font-family: 'helvetica_neue65_medium';
    line-height: 0;
    text-decoration: none;
}
.purple-btn:focus {
    color: #fff !important;
}
.purple-btn:active {
    color: #fff !important;
}

.mr-c-30 {
    margin-right: 30px;
}
.mr-c-50 {
    margin-right: 50px;
}
.login-form .modal-content {
    border-radius: 0px !important;
    padding: 30px;
}
.toggle-left label{
    font-family: 'helvetica_neue65_medium';
    font-size: 14px;
}
.toggle-switch-part {
    display: flex;
    justify-content: space-between;
}
.blue-link{
    color: #007AFF;
    font-size: 14px;
}
.blue-link:hover{
    color: #007AFF;
    font-size: 14px;
}
/*signup*/
.main-wrapper {
    margin-top: 20px;
    min-height: 100vh;
}
.forgetpage {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.white-card {
    background-color: #fff;
    padding: 60px;
}
.card-heading-md {
    font-size: 30px;
    color: #000;
    font-family: 'helveticabold';
    margin-bottom: 20px;
}
.card-pragraph-md{
    font-size: 20px;
    color: #000;
    font-family: 'helveticaregular';
    margin-bottom: 10px;
    line-height: 1.1;
}
.card-heading-md-second {
    font-size: 30px;
    color: #000;
    font-family: 'helveticabold';
    margin-bottom: 10px;
}
.card-pragraph-sm{
    font-size:15px;
    color: #000;
    font-family: 'helveticaregular';
    margin-bottom: 10px;
    line-height: 1.1;
}
.step-follow {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 60px;
}
.number-show {
    border: 1px solid #000;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    font-size: 24px;
    font-family: 'helvetica_neue65_medium';
}
.fill-circle {
    background-color: #000;
    color: #fff;
}
.step-follow li {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'helvetica_neue65_medium';
}
.step-title {
    font-size: 18px;
    color: #000;
    margin-top: 10px;
}
.form-label-show {
    font-size: 15px;
    color: #000;
    font-family: 'helveticaregular'; 
}
.form-label-show-bold {
    font-size: 15px;
    color: #000;
    font-family: 'helveticabold';
}
.form-input-show {
    border: 1px solid #A9A9A9;
    height: 40px;
    padding-left: 20px;
    width: 100%;
    font-size: 16px;
    font-family: 'helveticaregular';
    margin-top: 10px;
    padding-bottom: 1px;
}
.form-input-show::placeholder {
    font-size: 16px;
}
.form-input-show:focus {
    outline: none;
}
.sub-label-show {
    font-family: 'helveticaregular';  
}
.pragraph-second {
    text-align: center;
    margin-top: 40px;
}
.form-btn-part {
    text-align: center;
    margin-top: 50px;
}
.green-btn-square-form {
    background-color: #00FF37;
    border: none;
    color: #000;
    width: 100%;
    border-radius: 0px;
    height: 40px;
    text-align: center;
    font-family: 'helveticabold';
    line-height: 0;
}
.green-btn-square-form:hover {
    background-color: #00FF37;
    color: #000;
}
.green-btn-square-form:focus {
    background-color: #00FF37;
    color: #000 !important;
}

.green-btn-square-form-second {
    background-color: #00FF37;
    border: none;
    color: #000;
    width:100%;
    border-radius: 0px;
    height: 40px;
    text-align: center;
    font-family: 'helveticabold';
    line-height: 0;
}
.green-btn-square-form-second:hover {
    background-color: #00FF37;
    color: #000;
}
.green-btn-square-form-second:focus {
    background-color: #00FF37;
    color: #000 !important;
}
.green-btn-square-form-second:active {
    background-color: #00FF37 !important;
    color: #000 !important;
}
.black-outline-btn-square-form {
    background-color: #fff;
    border: none;
    color: #000;
    width:100%;
    border-radius: 0px;
    height: 40px;
    text-align: center;
    font-family: 'helvetica_neue65_medium';
    border: 1px solid #000;
}
.gry-text-light {
    color: #A9A9A9;
    font-size: 14px;
    padding: 14px 0px;
}
/*footer*/
.dark-bg-black {
    background-image: url(../Image/footer-bg.png);
    background-color: #000;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: bottom right;
}
.footer-menu {
    padding: 0;
    margin: 0;
    margin-top: 110px;
    list-style: none;
    font-family: 'helveticabold'; 
}

.normal-text-black{
    font-family: 'helveticaregular'; 
}
.bold-text-black a{
    font-family: 'helveticabold';
}
.sendagain{
    color: #000;
    text-decoration: none;
}
.form-btn-part-second {
    margin-bottom: 30px;
    text-align: center;
}
.box-shadow-card{
    box-shadow: 0px 14px 44px -24px rgba(0, 0, 0, 0.30); 
}
.how-content {
    font-family: 'helveticabold';
    font-size: 16px;
    text-transform: uppercase;
    width: 70%;
    margin: 0px auto 20px auto;
}
.border-right-colum {
    border-right: 1px solid #D1D0CF;
  
}
.how-to-use {
    margin-top: 80px;
    padding-bottom: 80px;
}
.icon-how {
    margin-bottom: 20px;
    padding-top: 20px;
}
.up-arrow-section {
    background-color: #fff;
    text-align: center;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dark-blue-link{
    color: #222B5E;
    text-decoration: none;
    font-family: 'helvetica_neue65_medium';
}
.dark-blue-link-btn {
    color: #222B5E;
    text-decoration: none;
    font-family: 'helvetica_neue65_medium';
    background-color: transparent !important;
    box-shadow: none;
    outline: none;
    border: none;
}
.gry-list-heading{
    font-family: 'helveticabold';
    font-size: 14px;
    margin-bottom: 20px;
}
.gry-list-points{
    font-family: 'helveticabold';
    font-size: 14px;
}
.gry-list-points li {
    display: flex;
    align-items: center;
}
.gry-list-points {
    list-style: none;
}
.gry-list-points p {
    margin: 0;
    margin-left: 20px;
}
.gry-bg-card {
    background-color: #F6F6F6;
    padding: 30px;
}
.green-text-lg {
    font-size: 58px;
    text-align: center;
    color: #00ff37;
    font-family: 'helveticabold';
}
.green-text-sm-bid {
    font-size: 22px;
    text-align: center;
    color: #00ff37;
    font-family: 'helveticabold';
    margin-top: 10px;
}
.green-text-ptheme{
    color: #00ff37;
}
.black-text-lg {
    font-size: 58px;
    text-align: center;
    color: #000;
    font-family: 'helveticabold';
}
.label-in {
    font-size: 15px;
    color: #000;
    font-family: 'helveticaregular';
}
.vrm-millage-group {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vrm-text-part {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vrm-value {
    font-size: 15px;
    color: #000;
    font-family: 'helveticaregular';
    margin-left: 20px;
}
.gry-text-italic {
    color: #A9A9A9;
    font-family: 'helveticabold';
    text-align: center;
    font-style: italic;
    margin-bottom: 30px;
}
.gry-text-italic ul {
    margin: 0;
    padding: 0;
}
.gry-text-italic p {
    margin: 0;
}
.list-italic {
    display: flex;
    justify-content: center;
}
.gry-text-italic ul li {
    list-style: disc;
}
.black-text-label {
    font-family: 'helveticabold';
    text-align: center;
}
.m-minus-top{
    margin-top: -40px;
}
.btn-overlap {
    margin-bottom: -40px;
}
.back-arrow-part img {
    margin-right: 15px;
}
.back-arrow-part a {
    font-family: 'helveticabold';
    font-size: 20px;
    color: #000;
    text-decoration: none;
}
.mt-150{
    margin-top: 150px;
}
.checkbox-part li {
    display: flex;
}
.check-input {
    opacity: 0;
    width: 80px;
    height: 40px;
    z-index: 2;
    cursor: pointer;
  }
  
  .box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 40px;
    background-color: #fff;
    transition: all .5s;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #999;
    color: #999;
}
.check-input:checked + .box {
    color: #fff;
}
  /* .box::before {
    content: "1";
    position: absolute;
    color: white;
    transition: all .5s;
  } */
  
  /* .box::after {
    content: "1";
    position: absolute;
    top: 40px;
    transition: all .5s;
  } */
  
  /* .check-input:checked + .box::before {
    color: #999;
}
  .check-input:checked + .box::after {
    color: white;
  } */
  
  .check-input:checked + .box {
    background-color: #000;
  }
  
  .checkbox-part {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.card-hading-size25 {
    font-size: 24px;
    color: #000;
    font-family: 'helveticabold';
    margin-bottom: 10px;
    text-align: center;
}
.white-card-sm {
    background-color: #fff;
    padding: 30px;
}
.home-loged-hading{
    margin-top: 30px;
    font-size: 36px;
    color: #000;
    font-family: 'helveticabold';
    margin-bottom: 60px;
}
.error {
    color: red;
    font-size: 13px;
}
.bg-cover-img{
    background-image: url(../Image/bg-cover.jpeg);
    text-align: center;
    height: 520px;
    background-repeat: no-repeat;
    padding-top: 130px;
    background-size: cover;
    background-position: bottom;

}

.green-bold-text{
    font-family: 'made_outer_sansblack';
    font-size: 75px;
    color: #00ff37;
    line-height: 1;

}
.white-bold-text{
    font-family: 'made_outer_sansblack';
    font-size: 75px;
    color: #fff;
    line-height: 1.5;
}
.mt-minus-150 {
    margin-top: -150px;
}
.white-card-p-20 {
    background-color: #fff;
    padding: 20px;
}
.sub-header-tab {
    list-style: none;
    padding: 0px;
    display: flex;   
    margin: 0px;
}
.sub-header-tab li{  
    padding-right: 20px;
}
.sub-header-tab a{  
    color: #0A4949; 
    text-decoration: none;
    font-family: 'helvetica_mediumregular';
}
.sub-header-tab .active{  
    text-decoration: underline;
    font-family: 'helveticabold';
}
.sub-header-part {
    background-color: #fff;
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom:1px solid #000 ;
}
.navbar-toggler-icon{
    background-image: url(../Image/humburger.svg);
    background-size: 100%;
    height: 40px;
}
.input-center{
    text-align: center;
    padding-left: 0px;
}
.group-input-signup input {
    border: 1px solid #A9A9A9;
    width: 90% !important;
    border-left: 0px;
}
.group-input-signup .input-group-prepend{
    width: 10%;
    background-color: transparent;
    
}
.group-input-signup .input-group-text{
    background-color: transparent;
    border: 1px solid #A9A9A9;
    border-radius: 0px;
    border-right: 0px;
}
.group-input-signup input:focus {
    outline: none;
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.password-group-input {
    position: relative;
}
.eye-password {
    position: absolute;
    right: 10px;
    top: 18px;
    width: 18px !important;
    cursor: pointer;
}
.placholder-text{    
    height: calc(100vh - 594px);
    min-height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.placholder-text h2{
    font-size: 55px;
    text-align: center;
    font-weight: bolder;
    opacity: 0.2;
}
.green-anchor-btn {
    background: #00ff37;
    color: #000;
    text-decoration: none;
    padding: 20px 30px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-top: 10px;
    font-size: 16px;
    font-family: 'helvetica_neue65_medium';
}
/*responsive*/  
  .tooltipheading .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: white;
    color: #000;
    text-align: center;
    padding: 5px 20px;
    position: absolute;
    z-index: 999999 !important;
    top: 65px;
    left: 50%;
    transform: translate(-50%, 0px);
    font-size: 14px;
    box-shadow: 0px 1px 10px #ececec;
}
  
  .tooltipheading:hover .tooltiptext {
    visibility: visible;
  }
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loader img {
    width: 50px;
}
.purple-btn-square-form-second {
    background-color: #7472F6;
    border: none;
    color: #fff;
    width:100%;
    border-radius: 0px;
    height: 40px;
    text-align: center;
    font-family: 'helveticabold';
    line-height: 0;
}
.purple-btn-square-form-second:hover {
    background-color: #7472F6;
    color: #fff;
}
.purple-btn-square-form-second:focus {
    background-color: #7472F6 !important;
    color: #fff !important;
}
.purple-btn-square-form-second:active {
    background-color: #7472F6 !important;
    color: #fff !important;
}
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.pagination-container .disabled a {
    color: black !important;
    text-decoration: none;
}
.pagination-container .next a {
    color: black !important;
    text-decoration: none;
}
.pagination-container .previous  a {
    color: black !important;
    text-decoration: none;
}
.pagination-container .active a {
    color: #000!important;
    background-color: #00ff37;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1 !important;

}
.pagination-container ul li {
    margin: 0px 5px;
}
.pagination-container ul li {
    color: #8e8e8e!important;
}
.pagination-container ul.pagination {
    background-color: #fff;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #efefef;
}
.coming-soon h1 {
    font-size: 74px;
    margin: 0px;
}

.coming-soon {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'made_outer_sansblack';
    background-color: #cac4b7;
    background-image: url("../../Assets/Image/coming-bg.png");
    background-size: cover;
    color: #fff;
    background-repeat: no-repeat;
}
.coming-soon-img {
    width: 600px;
    margin-top: 30px;
}
.coming-soon p {
    color: #11190C;
    font-size: 18px;
    line-height: 1.2;
    font-family: 'made_outer_sansblack';
    margin-top: 20px;
}